import React, { useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import Modal from "react-modal";
import { NotificationManager } from "react-notifications";
import { convertSnakeToStandardCasing, getConfirmAlert } from "../../../../utilities";
import FormattedMoney from "../../../utilityComponents/FormattedMoney";
import { disablePoolGuarantee } from "../../../../store/actions/moneyPool";
import ChangePoolGuaranteeLimit from "./ChangePoolGuaranteeLimit";

const ViewPoolGuarantee = ({
  setIsPageLoading,
  specificPoolGuarantee,
  setSpecificPoolGuarantee,
  dispatch,
  handleReloadGuarantees
}) => {
  const [isChangeGuaranteeLimit, setIsChangeGuaranteeLimit] = useState(false)

  return (
    <div>
      <div className="row padding-bottom-12">
        <b className="col-4">Status</b>
        <div className="col-8 d-flex justify-content-between align-items-center">
          <span className={`padding-right-10`} >{convertSnakeToStandardCasing(specificPoolGuarantee.status)}</span>
          <div>
            {
              specificPoolGuarantee.status != "DISABLED" &&
              <button
                type="button"
                className="btn bg-red"
                onClick={async () => {
                  getConfirmAlert({
                    title: "Confirm",
                    message:
                      `Are you sure you want to disable this pool guarantee? Once disabled, only our admin can re-enable it.`,
                    onClickYes: () => {
                      setIsPageLoading([true, "Loading..."]);
                      dispatch(disablePoolGuarantee(specificPoolGuarantee.id))
                        .then((res) => {
                          setIsPageLoading([false]);
                          if (res.error) {
                            NotificationManager.error(res.error.message, "Error!")
                          } else {
                            setSpecificPoolGuarantee(res.data)
                            NotificationManager.success(res.message, "Success!")
                            handleReloadGuarantees()
                          }
                        })
                    },
                    onClickNo: () => { },
                    variant: 'danger'
                  });
                }}
              >
                Disable
              </button>
            }
          </div>
        </div>
      </div>

      <div className="row padding-top-12 padding-bottom-12">
        <b className="col-4">Guarantee ID</b>
        <div className="col-8">{specificPoolGuarantee.id ? "#" + specificPoolGuarantee.id : "-"}</div>
      </div>

      <div className="row padding-top-12 padding-bottom-12">
        <b className="col-4">Guarantee</b>
        <div className="col-8">{specificPoolGuarantee.guarantee_full_name ? specificPoolGuarantee.guarantee_full_name : "-"}</div>
      </div>

      <div className="row padding-top-12 padding-bottom-12">
        <b className="col-4">Date</b>
        <div className="col-8">
          {moment(specificPoolGuarantee.created_at).format("DD MMM YYYY")}
        </div>
      </div>

      <div className="row padding-top-12 padding-bottom-12">
        <b className="col-4"> Limit</b>
        <div className="col-8">
          <div className="d-flex justify-content-between align-items-center" >
            <span
              className="margin-right-10">
              <FormattedMoney
                value={specificPoolGuarantee.guarantee_limit}
                currency={specificPoolGuarantee.guarantee_limit_currency}
              />
            </span>
            <button
              type="type"
              className="btn bg-orange fixed-width-120"
              onClick={() => {
                getConfirmAlert({
                  title: "Confirm",
                  message:
                    `Are you sure you want to change this guarantee limit? Increasing the limit requires the approval of our admin. 
                    Reducing the limit does not require approval.`,
                  onClickYes: () => {
                    setIsChangeGuaranteeLimit(true)
                  },
                  onClickNo: () => { },
                  variant: "danger",
                })
              }} >Change Limit</button>
          </div>
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isChangeGuaranteeLimit}
        onRequestClose={() => {
          setIsChangeGuaranteeLimit(false)
        }}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue text-center stretch-full">
            Change Guarantee Limit
          </h1>
          <i
            onClick={() => {
              setIsChangeGuaranteeLimit(false)
            }}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line" />

        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
        >
          <ChangePoolGuaranteeLimit
            poolGuarantee={specificPoolGuarantee}
            setSpecificPoolGuarantee={setSpecificPoolGuarantee}
            setIsPageLoading={setIsPageLoading}
            handleReloadGuarantees={handleReloadGuarantees}
            handleClose={() => {
              setIsChangeGuaranteeLimit(false)
            }} />
        </div>
      </Modal>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ViewPoolGuarantee);
